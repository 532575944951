import { AnimationEvent, useEffect, useRef } from "react";
import cn from 'classnames';
import { Color } from "../../../game";
import "./liquid.scss";

const convertColorToCssClass = (c: Color) => c?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

interface Props {
    color: Color
}

export function Liquid({ color }: Props) {
    const colorRef = useRef(color);

    const hasChanges = colorRef.current !== color;

    let colorClassName = color ? convertColorToCssClass(color) : '';
    let animationClassName = "";

    useEffect(() => {
        colorRef.current = color;
    }, [color])

    if (hasChanges) {
        //add previous color for water level down animation
        if (!colorClassName && colorRef.current != null) {
            colorClassName = convertColorToCssClass(colorRef.current)!;
        }

        animationClassName = color
            ? 'liquid-level-up-animation'
            : 'liquid-level-down-animation';
    }

    const handleAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;

        if (!color) {
            target.classList.remove(colorClassName);
        }

        if (animationClassName) {
            target.classList.remove(animationClassName);
        }
    }

    return (
        <div
            className={cn('liquid', colorClassName, animationClassName)}
            onAnimationEnd={handleAnimationEnd}
        >
            &nbsp;
        </div>
    );
}
