import { useEffect, useRef } from 'react';

interface ClickOutsideProps {
    children: React.ReactNode;
    onClick: (e: MouseEvent) => void;
    className?: string;
}

export function ClickOutside({ children, onClick, className }: ClickOutsideProps) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(e: MouseEvent) {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                e.stopPropagation();
                if (onClick) onClick(e);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside); // eslint-disable-next-line
    }, []);

    return (
        <div ref={ref} className={className}>{children}</div>
    )
}
