import cn from 'classnames';
import "./switch.scss";

interface Props {
    checked: boolean
    onToggle?: (checked: boolean) => void
}

export function Switch({ checked, onToggle }: Props) {
    return <div 
        className={cn("switch", { checked })} 
        onClick={() => onToggle?.(!checked)}
    />
}