import { useState } from "react";
import { Icon } from "../../../images/icons/Icon";
import { ControlBarButton } from "../control-bar/ControlBarButton";
import { ClickOutside } from "../../common/ClickOutside";
import { usePuzzleContext } from "../../../state/PuzzleStateProvider";
import { DropDownMenu } from "../../common/drop-down-menu/DropDownMenu";
import { DropDownMenuItem } from "../../common/drop-down-menu/DropDownMenuItem";
import { Switch } from "../../common/switch/Switch";
import './settings-menu.scss';

export function SettingsMenuButton() {
    const [menuVisible, setMenuVisible] = useState(false);

    const { state, toggleSound } = usePuzzleContext();

    const handleToggleSoundMenuItemClick = () => {
        toggleSound();
        setTimeout(() => setMenuVisible(false), 200);
    }

    return (
        <ClickOutside className="settings-menu-container" onClick={() => setMenuVisible(false)}>
            <ControlBarButton small onClick={() => setMenuVisible(!menuVisible)}>
                <Icon name="settings" width="24px" height="24px" />
            </ControlBarButton>
            {
                menuVisible &&
                <DropDownMenu className="settings-menu">
                    <DropDownMenuItem
                        text="Sound effects"
                        onClick={handleToggleSoundMenuItemClick}
                    >
                        <Switch checked={!state.mute} />
                    </DropDownMenuItem>
                </DropDownMenu>
            }
        </ClickOutside>
    );
}