const isValidDate = (d: Date) => d instanceof Date && !isNaN(d.getTime())

const resetTime = (date: Date) => new Date(date.setUTCHours(0,0,0,0));

const dateUtils = {
    isValidDate,
    resetTime
}

export default dateUtils;
