import cn from 'classnames';
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

export interface ControlBarButtonsProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
    small?: boolean
}

export function ControlBarButton({ small, children, ...rest }: ControlBarButtonsProps) {
    return (
        <button className={cn("control-bar-button", { small })} {...rest}>{children}</button>
    );
}
