import { useDelayedState } from "../../../hooks/useDelayedState";
import { usePuzzleContext } from "../../../state/PuzzleStateProvider";
import { Tube as TubeEntry, isPuzzleDone } from "../../../game";
import { Animate } from "../../common/animate/Animate";
import { Tube } from "../tube/Tube";
import { LevelDone } from "../../level-done/LevelDone";
import { BUBBLES_ANIMATION_DURATION_SEC } from "../bubble/Bubble";
import './puzzle-area.scss';

export function PuzzleAreaView() {
    const { state, selectTube, resetSelectedTube, pourLiquid } = usePuzzleContext();
    const isPuzzleDoneFlag = isPuzzleDone(state.puzzle)
    const [isDone] = useDelayedState(isPuzzleDoneFlag, isPuzzleDoneFlag ? BUBBLES_ANIMATION_DURATION_SEC : 0);


    const handleTubeClick = (tube: TubeEntry) => {
        if (state.selectedTubeId === tube.id) { // reset selection
            resetSelectedTube();
            return;
        }

        const isEmpty = (b: TubeEntry) => !b.liquid.length;
        const isFull = (b: TubeEntry) => b.liquid.length === state.puzzle.tubeSize
        const isCompleted = (b: TubeEntry) => isFull(b) && new Set(b.liquid).size === 1
        const getEmptySpace = (b: TubeEntry) => state.puzzle.tubeSize - b.liquid.length
        const getSameColorTopLiquidCapacity = (b: TubeEntry) => {
            if (!b.liquid.length) return 0;

            const topColor = b.liquid[0];
            let i = 1

            while (b.liquid[i] === topColor) {
                i++;
            }

            return i;
        }

        if (isCompleted(tube)) return;

        if (state.selectedTubeId) {
            const selectedTube = state.puzzle.tubes.find(b => b.id === state.selectedTubeId);

            if (!selectedTube) throw Error('Invalid selectedTubeId=' + state.selectedTubeId);

            if (isFull(tube) || isCompleted(selectedTube) || isEmpty(selectedTube)) {
                return;
            } else {
                if (isEmpty(tube) || (selectedTube.liquid[0] === tube.liquid[0])) {
                    const sourceLiquidCapacity = getSameColorTopLiquidCapacity(selectedTube);
                    const targetTubeEmptySpace = getEmptySpace(tube);

                    pourLiquid(selectedTube.id, tube.id, Math.min(sourceLiquidCapacity, targetTubeEmptySpace));
                }
            }
        } else if (!isEmpty(tube)) { // no selected tube
            selectTube(tube.id);
        }
    }

    return (
        <>
            <Animate visible={isDone}>
                <LevelDone />
            </Animate>
            <Animate visible={!isDone}>
                <div className="puzzle-area">
                    {state.puzzle.tubes.map(t =>
                        <Tube
                            key={t.id}
                            tube={t}
                            capacity={state.puzzle.tubeSize}
                            selected={t.id === state.selectedTubeId}
                            onClick={handleTubeClick}
                        />)}
                </div>
            </Animate>
        </>
    )
}
