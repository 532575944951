import chemistBoy from "../../images/chemist-boy.png";

export function ImagePreload() {
    return (
        <>
            {[chemistBoy].map((e, i) => (
                <img key={i} alt={i.toString()} src={e} style={{ display: "none" }} />
            ))}
        </>
    );
}