import cn from 'classnames';
import { PropsWithChildren } from "react";
import { Popover } from '../popover/Popover';
import "./drop-down-menu.scss";

interface Props extends PropsWithChildren {
    className?: string;
}
export function DropDownMenu({ className, children }: Props) {
    return (
        <Popover className={cn("drop-down-menu", className)}>{children}</Popover>
    );
}