import { useEffect } from "react";
import { isPuzzleDone } from "../game";
import { usePuzzleContext } from "../state/PuzzleStateProvider";
import { gameService } from "../data/game.service";

export function useLevelAutoSave(tubeReward: boolean) {
    const { state } = usePuzzleContext();
    const isDone = isPuzzleDone(state.puzzle);

    useEffect(() => {
        if (isDone) {
            const savedGame = gameService.getCurrentGameOrDefault();
            if (state.level === savedGame.level) {
                gameService.nextLevel(!state.isRetryAttempt, tubeReward);
            }
        }
    }, [isDone, state.isRetryAttempt, state.level, tubeReward])
}