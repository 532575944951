export const numberUtils = {
    isNumeric,
    numberOrDefault
}

function isNumeric(value: any) {
    return (
        typeof (value) === 'number' || (
            typeof (value) === "string" &&
            value.trim() !== '' &&
            !isNaN(value as unknown as number)
        )
    );
}

function numberOrDefault(value?: any, defaultValue?: number) {
    return isNumeric(value) ? Number(value) : defaultValue
}

