import { PropsWithChildren } from "react";
import './pie-chart.scss';

interface Props extends PropsWithChildren {
    value: number
    visible?: boolean
}

export function PieChart({ value, visible = true, children }: Props) {
    return (
        <div className="pie-chart"
            style={{
                background: visible 
                    ? `conic-gradient(#00ff00 ${value}%, rgba(0,255,0, .20) ${value}%)`
                    : 'transparent'
            }}
        >
            {children}
        </div>
    );
}