function Medal() {
  return (
    <svg className="medal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 93">
      <g className="layer">
        <path
          fill="#ff97b7"
          d="M50.8 44.79l-15.7 7.9-15.7-7.9L0 83.59l12.1-1.2 6.2 10.4 16.8-33.4 16.8 33.4 6.2-10.4 12.1 1.2-19.4-38.8z"
        ></path>
        <path
          fill="#ffc54d"
          d="M67.9 24.69l-1.1 4.2c-.3 1.4-.1 2.9.6 4.2l2.1 3.8c1.3 2.2.9 5.1-1.1 6.9l-3.2 2.9c-1.1 1-1.7 2.4-1.8 3.8l-.2 4.3c-.2 2.6-2 4.8-4.6 5.2l-4.3.8c-1.4.3-2.7 1.1-3.6 2.2l-2.5 3.5c-1.5 2.1-4.3 2.9-6.6 1.9l-4-1.7c-1.4-.6-2.8-.6-4.2 0l-4.1 1.6c-2.4.9-5.2.1-6.7-2l-2.5-3.7c-.8-1.2-2.1-2-3.5-2.3l-4.2-.8c-2.6-.5-4.4-2.7-4.5-5.3l-.2-4.4c0-1.5-.6-2.8-1.7-3.8l-3.2-3c-1.9-1.8-2.3-4.6-.9-6.9l2.3-3.8c.8-1.2 1-2.7.6-4.1l-1-4.3c-.6-2.5.6-5.1 2.9-6.3l3.9-1.9c1.3-.6 2.3-1.8 2.8-3.1l1.4-4.2c.9-2.4 3.3-4 5.9-3.7l4.4.4c1.4.2 2.9-.2 4.1-1.2l3.4-2.7c2-1.6 4.9-1.6 6.9.1l3.4 2.7c1.2.9 2.6 1.4 4 1.2l4.3-.4c2.6-.3 5 1.3 5.8 3.8l1.3 4.1c.4 1.4 1.5 2.6 2.8 3.2l3.9 2c2.3 1.7 3.6 4.3 2.9 6.8z"
        ></path>
        <circle cx="35.1" cy="35.09" r="25.4" fill="#e8b04b"></circle>
      </g>
      <g>
        <text fill="#fff" fontSize="1rem" fontWeight={700} x="40%" y="30%">1</text>
        <text fill="#fff" fontSize=".5rem" x="52%" y="30%">st</text>
        <text fill="#fff" fontSize=".5rem" x="50%" y="39%" dominantBaseline="middle" textAnchor="middle">Attempt</text>
        <text fill="#fff" fontSize=".5rem" x="50%" y="51%" dominantBaseline="middle" textAnchor="middle">Pass</text>
      </g>
    </svg>
  );
}

export default Medal;