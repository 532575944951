import { ReactElement, useEffect, useState } from 'react';
import cn from 'classnames';
import { checkTubeReward } from "../../game";
import { usePuzzleContext } from "../../state/PuzzleStateProvider";
import chemistBoyPicture from '../../images/chemist-boy.png';
import { Button } from '../common/button/Button';
import { useLevelAutoSave } from '../../hooks/useLevelAutoSave';
import useAudio from '../../hooks/useAudio';
import { Confetti } from '../common/animate/confetti/Confetti';
import './level-done.scss';
import Medal from '../../images/Medal';

interface Props {
    className?: string
}

enum LevelCompletionType {
    Passed,
    FirstAttemptPass
}

type TContentOptions = {
    [key in LevelCompletionType]: {
        title: string
        text: string
        image: () => ReactElement
    }
}

const contentOptions: TContentOptions = {
    [LevelCompletionType.Passed]: {
        title: "level completed",
        text: "Well done! Keep up the good work!",
        image: () => <img alt="" src={chemistBoyPicture} />
    },
    [LevelCompletionType.FirstAttemptPass]: {
        title: "congratulation!",
        text: "You passed the level on the first try. Great job!",
        image: Medal
    }
};

export function LevelDone({ className }: Props) {
    const [audioPlayed, setAudioPlayed] = useState(false);
    const { state, nextLevel } = usePuzzleContext();
    const { playFanfare } = useAudio();

    const tubeReward = checkTubeReward(state.level, state.remainingEmptyTubes, state.emptyTubeUsageLevel)

    useEffect(() => {
        if (!audioPlayed) {
            playFanfare();
            setAudioPlayed(true);
        }
    }, [audioPlayed, playFanfare])

    useLevelAutoSave(tubeReward);


    const content = state.isRetryAttempt
        ? contentOptions[LevelCompletionType.Passed]
        : contentOptions[LevelCompletionType.FirstAttemptPass];

    return (
        <>
            <Confetti />
            <div className={cn("level-done", className)}>
                <div className="text">
                    <h2>{content.title}</h2>
                    <p>{content.text}</p>
                </div>
                <content.image />
                <Button onClick={() => nextLevel()}>
                    continue
                </Button>
            </div>
        </>
    );
}