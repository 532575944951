import { PuzzleStateProvider } from '../state/PuzzleStateProvider';
import { ImagePreload } from './common/ImagePreload';
import { PuzzleAreaView } from './game/puzzle-area/PuzzleAreaView';
import GoogleAds from './google-ads/GoogleAds';
import { Header } from './header/Header';

function App() {
  return (
    <PuzzleStateProvider>
      <Header />
      <PuzzleAreaView />
      <GoogleAds />
      <ImagePreload />
    </PuzzleStateProvider>
  );
}

export default App;
