import { memo } from "react";
import { appSettings } from "../../settings/app-settings";
import "./google-ads.scss";

function GoogleAds() {
    if (!appSettings.googleAds) return null;

    return (
        <>
            <script data-adbreak-test="on" async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2076661589628827"
                crossOrigin="anonymous"></script>
            <ins className="adsbygoogle google-ads-bottom-banner" style={{ display: "block" }} data-adbreak-test="on" data-ad-client="ca-pub-2076661589628827"
                data-ad-slot="7560470094" data-ad-format="auto" data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
            </script>
        </>
    );
}

export default memo(GoogleAds)