import cn from 'classnames';
import './bubble.scss';

export const BUBBLES_ANIMATION_DURATION_SEC = 3;

interface Props {
    size: 'xs' | 's' | 'md' | 'lg'
    onAnimationEnd?: () => void;
}

export function Bubble({ size, onAnimationEnd }: Props) {

    return (
        <p className={cn("bubble", size)} onAnimationEnd={onAnimationEnd} />
    );
}