import { cloneElement } from "react";
import cn from 'classnames';
import { useDelayedState } from "../../../hooks/useDelayedState";
import './animate.scss';

interface AnimateProps {
    visible: boolean
    animationType?: 'slide-in/slide-out'
    children: React.ReactElement
}

export function Animate({ visible, animationType = 'slide-in/slide-out', children }: AnimateProps) {
    const [_visible] = useDelayedState(visible);

    if (!_visible) return null;

    const [show, hide] = animationType.split('/');

    return cloneElement(
        children, {
        className: cn(children.props.className, "animate", visible ? show : hide)
    });
}