import { appSettings } from "../settings/app-settings"
import dateUtils from "../utils/date.utils"
import { numberUtils } from "../utils/number.utils"

export interface Game {
    level: number
    firstAttemptLevelsPass: number
    days: {
        [dateStamp: string]: {
            levels: number
            firstAttemptLevelsPass: number
        }
    },
    tubeHelper: number
    emptyTubeUsageLevel?: number
    mute: boolean
}

export const gameService = {
    getCurrentGameOrDefault,
    hasSavedGame,
    saveCurrentGame,
    nextLevel,
    useTubeHelper,
    toggleSound,
}

const key = "game";

export function createDateStamp(date: Date = new Date()){
    return dateUtils.resetTime(date).toISOString();
}

function getCurrentGameOrDefault(): Game {
    const storageRecord = localStorage.getItem(key);
    let game: Game | null = null;


    if (storageRecord) {
        try {
            game = JSON.parse(storageRecord) as Game;
        } catch { }
    }

    const tubeHelper = Math.min(1, numberUtils.numberOrDefault(game?.tubeHelper, 1)!);
    const level = numberUtils.numberOrDefault(game?.level, 1)!;

    const parseFirstAttemptLevelsPass = () => {
        const value = numberUtils.numberOrDefault(game?.firstAttemptLevelsPass, 0)!;

        return value <= level ? value : 0;
    }

    const parseEmptyTubeUsageLevel = () => {
        if (tubeHelper || game?.emptyTubeUsageLevel == null) return undefined

        const value = numberUtils.numberOrDefault(game?.emptyTubeUsageLevel, 0)!;

        return value <= level ? value : 0;
    }

    const isObject = (obj: any) => {
        return obj !== undefined && obj !== null && obj.constructor === Object;
    }

    return {
        level,
        firstAttemptLevelsPass: parseFirstAttemptLevelsPass(),
        days: game?.days && isObject(game?.days) ? game?.days : {},
        tubeHelper,
        emptyTubeUsageLevel: parseEmptyTubeUsageLevel(),
        mute: Boolean(game?.mute ?? false)
    }
}

function hasSavedGame() {
    return localStorage.getItem(key) != null;
}

function saveCurrentGame(game: Game) {
    if (!appSettings.saveGameEnabled) return;

    const storageRecord = JSON.stringify(game);

    try {
        localStorage.setItem(key, storageRecord);
    } catch { }
}

function nextLevel(isFirstAttemptPass: boolean, isTubeReward: boolean) {
    const updated = new GameStoreBuilder(getCurrentGameOrDefault())
        .nextLevel()
        .firstAttempt(isFirstAttemptPass)
        .tubeReward(isTubeReward)
        .build();

    saveCurrentGame(updated);
}

function useTubeHelper() {
    const game = new GameStoreBuilder(getCurrentGameOrDefault())
        .useTubeHelper()
        .build();

    saveCurrentGame(game);
}

function toggleSound() {
    const game = new GameStoreBuilder(getCurrentGameOrDefault())
        .toggleSound()
        .build();

    saveCurrentGame(game);
}

export class GameStoreBuilder {
    private _game: Game
    private _originalLevel: number

    constructor(game: Game) {
        this._game = game;
        this._originalLevel = game.level;
    }

    public nextLevel() {
        this._game.level = this._originalLevel + 1;

        const todayDateStamp = createDateStamp(); 

        if(this._game.days[todayDateStamp]) {
            this._game.days[todayDateStamp].levels += 1;
        } else {
            this._game.days[todayDateStamp] = {
                levels: 1,
                firstAttemptLevelsPass: 0
            }

        }

        return this;
    }

    public firstAttempt(isFirstAttemptPass: boolean) {
        if (!isFirstAttemptPass) return this;

        const today = this._game.days[createDateStamp()];
        if(!today) return this;

        this._game.firstAttemptLevelsPass += 1;
        today.firstAttemptLevelsPass += 1;

        return this;
    }

    public tubeReward(isTubeReward: boolean) {
        if (isTubeReward) {
            this._game.tubeHelper += 1;
            delete this._game.emptyTubeUsageLevel;
        }

        return this;
    }

    public toggleSound() {
        this._game.mute = !this._game.mute;

        return this;
    }

    public useTubeHelper() {
        if (!this._game.tubeHelper) return this;

        this._game.tubeHelper -= 1;
        this._game.emptyTubeUsageLevel = this._game.level;

        return this;
    }

    build() {
        return this._game;
    }
}