import cn from 'classnames';
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import './button.scss';

interface Props extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
    secondary?: boolean
}

export function Button({ secondary = false, children, ...rest }: Props) {
    return (
        <button {...rest} className={cn({ secondary, main: !secondary })}>
            {children}
        </button>
    );
}