import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Tube as TubeEntry } from "../../../game";
import { Bubble } from "../bubble/Bubble";
import { Liquid } from "../liquid/Liquid";
import './tube.scss';
import useAudio from "../../../hooks/useAudio";

interface TubeProps {
    capacity: number
    tube: TubeEntry
    selected: boolean
    onClick: (tube: TubeEntry) => void
}
export function Tube({ tube, selected, capacity, onClick }: TubeProps) {
    const tubeCapacityRef = useRef(tube.liquid.length);
    const [bubblesAnimationDone, setBubblesAnimationDone] = useState(false);
    const { playPick, playLiquidPour } = useAudio();

    useEffect(() => {
        if(selected){
            playPick();
        }
    }, [selected, playPick])

    useEffect(() => {
        if(tubeCapacityRef.current < tube.liquid.length) {
            playLiquidPour();
        }
        tubeCapacityRef.current = tube.liquid.length; // actualize
    }, [tube.liquid.length, playLiquidPour])

    const done =
        tube.liquid.length === capacity &&
        new Set(tube.liquid).size === 1;

    const classNames = cn(
        "tube", `capacity-${capacity}`,
        { done: done && bubblesAnimationDone, selected, overflow: tube.liquid.length !== capacity }
    )

    const liquid = [...tube.liquid].reverse();

    return (
        <div className={classNames} onClick={() => onClick(tube)}>
            {<Liquid color={liquid[0]} />}
            {<Liquid color={liquid[1]} />}
            {capacity >= 3 && <Liquid color={liquid[2]} />}
            {capacity >= 4 && <Liquid color={liquid[3]} />}
            {done &&
                <>
                    <Bubble size="xs" onAnimationEnd={() => setBubblesAnimationDone(true)} />
                    <Bubble size="s" />
                    <Bubble size="md" />
                    <Bubble size="md" />
                    <Bubble size="lg" />
                    <Bubble size="xs" />
                    <Bubble size="s" />
                    <Bubble size="md" />
                    <Bubble size="s" />
                    <Bubble size="lg" />
                </>
            }
        </div>
    );
}