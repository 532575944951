import { useEffect, useState } from 'react';
import cn from 'classnames';
import './badge.scss';

interface Props {
    value: number
    notifyChanged?: boolean
}
export function Badge({ value, notifyChanged = false }: Props) {
    const [notified, setNotified] = useState(value);

    useEffect(() => {
        if(notified > value) {
            setNotified(value);
        }
    }, [notified, value])

    return (
        <div
            className={cn("badge", { update: notifyChanged && notified < value })}
            onAnimationEnd={() => setNotified(value)}>
            {value}
        </div>
    );
}