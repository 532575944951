import { useCallback, useEffect, useState } from "react";

export function useDelayedState<TValue>(value: TValue, delaySeconds: number = 0.5): [TValue, (value: TValue) => void] {
    const [_value, setValue] = useState(value);

    const setValueDelayed = useCallback((value: TValue) => {
        setTimeout(() => setValue(value), delaySeconds * 1000)
    }, [delaySeconds]);
    

    useEffect(() => {
        if (_value !== value) {
            setValueDelayed(value);
        }
    }, [value, _value, setValueDelayed])


    return [_value, setValueDelayed];
}