import { isPuzzleDone } from "../../game";
import { Icon } from "../../images/icons/Icon";
import { excludeReverseSteps } from "../../state";
import { usePuzzleContext } from "../../state/PuzzleStateProvider";
import { Badge } from "../common/badge/Badge";
import { ControlBar } from "./control-bar/ControlBar";
import { ControlBarButton, ControlBarButtonsProps } from "./control-bar/ControlBarButton";
import { Level } from "./level/Level";
import { appSettings } from "../../settings/app-settings";
import { SettingsMenuButton } from "./settings-menu/SettingsMenuButton";
import { PieChart } from "../common/pie-chart/PieChart";
import './header.scss';


export function Header() {
    return (
        <div className="header">
            <Level />
            <ControlBar>
                <UndoStepButton />
                <UseEmptyTubeButton />
                <RestartLevelButton />
                <SettingsMenuButton />
            </ControlBar>
        </div>
    )
}

export function UndoStepButton({ disabled }: ControlBarButtonsProps) {
    const { state, stepBack } = usePuzzleContext();

    return (
        <ControlBarButton
            disabled={
                disabled ||
                !state.remainingStepsBack || // all steps are used
                isPuzzleDone(state.puzzle) || // level is done
                !excludeReverseSteps(state.stepHistory).length // begining of the level
            }
            onClick={stepBack}
        >
            <Icon name="undo" height="24px" width="24px" />
            <Badge value={state.remainingStepsBack} />
        </ControlBarButton>
    );
}

export function UseEmptyTubeButton({ disabled }: ControlBarButtonsProps) {
    const { state, useEmptyTube } = usePuzzleContext();

    const calculateEmptyTubeRewardProgress = () => {
        const levelsProgress = state.level - (state.emptyTubeUsageLevel ?? 0) - 1;

        if (!levelsProgress) return 0;

        return 100 / appSettings.tubeRewardLevelsToPass * levelsProgress
    }

    const HandleUseEmptyTubeClick = () => useEmptyTube(state.level)

    return (
        <ControlBarButton
            disabled={
                disabled ||
                !state.remainingEmptyTubes ||
                isPuzzleDone(state.puzzle)
            }
            onClick={HandleUseEmptyTubeClick}
        >
            <Icon name="tube" height="26px" width="16px" />
            <PieChart
                value={calculateEmptyTubeRewardProgress()}
                visible={!state.remainingEmptyTubes && state.emptyTubeUsageLevel !== state.level}
            >
                <Badge value={state.remainingEmptyTubes} notifyChanged={!!state.remainingEmptyTubes} />
            </PieChart>
        </ControlBarButton>
    );
}

export function RestartLevelButton({ disabled }: ControlBarButtonsProps) {
    const { state, restartLevel } = usePuzzleContext();

    return (
        <ControlBarButton
            small
            disabled={
                disabled ||
                isPuzzleDone(state.puzzle) ||
                !excludeReverseSteps(state.stepHistory).length // level begining
            }
            onClick={restartLevel}
        >
            <Icon name="restart" height="24px" width="24px" />
        </ControlBarButton>

    );
}