interface Props {
    name: 'undo' | 'restart' | 'tube' | 'sound-off' | 'sound-on' | 'settings'
    width?: string
    height?: string
    color?: string
}
export function Icon({ name, width = "24px", height = "24px", color = "currentColor" }: Props) {
    switch (name) {
        case 'undo':
            return (
                <svg width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22">
                    <g>
                        <path clipRule="evenodd" d="M8.71 1.29a1 1 0 0 1 0 1.42L6.41 5H14a7 7 0 1 1 0 14H8.15a1 1 0 1 1 0-2H14a5 5 0 1 0 0-10H6.41l2.3 2.29a1 1 0 1 1-1.42 1.42L2.59 6l4.7-4.71a1 1 0 0 1 1.42 0z" fillRule="evenodd" />
                    </g>
                </svg>
            );
        case 'restart': return (
            <svg width={width} height={height} fill={color} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.06 10.893C2.596 5.91 6.73 2 11.786 2c2.86 0 5.428 1.253 7.213 3.24V4a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1h-4a1 1 0 1 1 0-2h1.867c-1.432-1.835-3.627-3-6.08-3-3.987 0-7.306 3.09-7.74 7.107a1 1 0 1 1-1.988-.214Zm18.994 1.113a1 1 0 0 1 .887 1.101C21.403 18.09 17.27 22 12.213 22 9.353 22 6.785 20.747 5 18.76V20a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H6.133c1.432 1.835 3.627 3 6.08 3 3.988 0 7.306-3.09 7.74-7.107a1 1 0 0 1 1.1-.887Z" />
            </svg>
        );
        case "tube": return (
            <svg width={width} height={height} fill={color} viewBox="0 0 220 522" xmlns="http://www.w3.org/2000/svg" strokeWidth="18" stroke={color} version="1.1" >
                <g >
                    <path d="m193.25,5l0,117.62l0,292.07c0,46.35 -37.58,83.94 -83.94,83.94c-46.36,0 -83.94,-37.59 -83.94,-83.94l0,-290.61l0,-119.08l-18.37,0l0,409.68c0,56.42 45.9,102.32 102.31,102.32c56.41,0 102.31,-45.9 102.31,-102.32l0,-409.68l-18.37,0z" id="svg_3" />
                    <rect height="18" width="192.8" x="12" y="5" />
                </g>
            </svg>
        );
        case 'sound-off': return (
            <svg viewBox="-1 -1 20 14" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
                <g stroke="#000" strokeWidth="1.35">
                    <path d="m14 8.13 2-2m2-2-2 2m0 0-2-2m2 2 2 2" strokeLinecap="round" strokeLinejoin="round" stroke={color} />
                    <path stroke={color} d="M0 8.13v-4c0-.55.45-1 1-1h2.7c.19 0 .39-.06.55-.17L8.45.17C9.11-.28 10 .2 10 1v10.26c0 .8-.89 1.28-1.55.83L4.25 9.3a.99.99 0 0 0-.55-.17H1c-.55 0-1-.45-1-1z" />
                </g>
            </svg>
        );
        case 'sound-on': return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="-1 -1 20 14" fill="none">
                <g stroke="#000" strokeWidth="1.35">
                    <path fill="none" d="M14.5 1.63s1.5 1.5 1.5 4-1.5 4-1.5 4" strokeLinecap="round" stroke={color} />
                    <path stroke={color} d="M0 8.13v-4c0-.55.45-1 1-1h2.7c.19 0 .39-.06.55-.17L8.45.17C9.11-.28 10 .2 10 1v10.26c0 .8-.89 1.28-1.55.83L4.25 9.3a.99.99 0 0 0-.55-.17H1c-.55 0-1-.45-1-1z" />
                </g>
            </svg>
        )
        case 'settings': return (
            <svg width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14">
                <g clipRule="evenodd" fillRule="evenodd">
                    <path d="M10.1 3.5H.75C.34 3.5 0 3.16 0 2.75S.34 2 .75 2h9.35c.33-1.15 1.39-2 2.65-2s2.32.85 2.65 2h1.35c.41 0 .75.34.75.75s-.34.75-.75.75H15.4c-.33 1.15-1.39 2-2.65 2s-2.32-.85-2.65-2zm1.4-.75a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0zM7.4 11.5h9.35c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H7.4c-.33-1.15-1.39-2-2.65-2s-2.32.85-2.65 2H.75c-.41 0-.75.34-.75.75s.34.75.75.75H2.1c.33 1.15 1.39 2 2.65 2s2.32-.85 2.65-2zm-3.9-.75a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0z" />
                </g>
            </svg>
        )
        default: return null;
    }
}

