import { useCallback } from "react";
import { appSettings } from "../settings/app-settings";
import { usePuzzleContext } from "../state/PuzzleStateProvider";
import { Howl } from 'howler'

import mp3 from '../audio/effects.mp3';
import webm from '../audio/effects.mp3';

const PICK = 'pick';
const LIQUID_POUR = 'liquid-pour';
const FANFARE = 'fanfare';

const howl = new Howl({
  src: [webm, mp3],
  sprite: {
    [PICK]: [0, 130.6122448979592],
    [LIQUID_POUR]: [2000, 496.32653061224505],
    [FANFARE]: [4000, 1776.0090702947844]
  }
});

function useAudio() {
  const { state } = usePuzzleContext();

  const play = useCallback((audio: string, volume: number = 1) => {
    if (!appSettings.soundOff && !state.mute) {
      Howler.volume(volume);
      howl.play(audio);
    }
  }, [state.mute])

  const playPick = useCallback(() => {
    play(PICK);
  }, [play])

  const playLiquidPour = useCallback(() => {
    play(LIQUID_POUR);
  }, [play])

  const playFanfare = useCallback(() => {
    play(FANFARE, 0.1);
  }, [play])

  return { playPick, playLiquidPour, playFanfare };
}

export default useAudio;