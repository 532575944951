import { usePuzzleContext } from "../../../state/PuzzleStateProvider";
import { appSettings } from "../../../settings/app-settings";
import './level.scss';

export function Level() {
    const { state } = usePuzzleContext();

    const renderContent = () => (
        <>
            <div className="level-number gradient-text">{state.level}</div>
            <div className="text gradient-text">level</div>
        </>
    )

    return appSettings.statsEnabled ? (
        <a className="level" href="#stats">
            {renderContent()}
        </a>
    ) : (
        <div className="level">
            {renderContent()}
        </div>
    )
}