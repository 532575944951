import cn from 'classnames';
import { PropsWithChildren } from "react";
import './popover.scss';

interface Props extends PropsWithChildren {
    className?: string
}

export function Popover({ className, children }: Props) {
    return <div className={cn("popover", className)}>{children}</div>
}