import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { actions, puzzleReducer, puzzleReducerInitialState } from ".";
import { Game, gameService } from "../data/game.service";

interface Props {
    children: React.ReactNode
}

export function PuzzleStateProvider({ children }: Props) {
    const context = usePuzzleContextData();
    const { restoreSavedGame } = context

    useEffect(() => {
        const savedGame = gameService.getCurrentGameOrDefault();
        restoreSavedGame(savedGame);
    }, [restoreSavedGame]);

    return (
        <PuzzleStateContext.Provider value={context}>
            {children}
        </PuzzleStateContext.Provider>
    );
}

function usePuzzleContextData() {
    const [state, dispatch] = useReducer(puzzleReducer, puzzleReducerInitialState);

    const selectTube = useCallback((tubeId: string) => dispatch(actions.selectTube(tubeId)), []);
    const resetSelectedTube = useCallback(() => dispatch(actions.resetSelectedTube()), []);
    const stepBack = useCallback(() => dispatch(actions.stepBack()), []);
    const useEmptyTube = useCallback((level: number) => {
        dispatch(actions.useEmptyTube())
        gameService.useTubeHelper();
    }, []);
    const pourLiquid = useCallback((sourceId: string, targetId: string, capacity: number, stepBack?: boolean) =>
        dispatch(actions.pourLiquid(sourceId, targetId, capacity)), [])
    const nextLevel = useCallback(() => { dispatch(actions.nextLevel()) }, []);
    const restartLevel = useCallback(() => { dispatch(actions.restartLevel()) }, []);
    const restoreSavedGame = useCallback((game: Game) => { dispatch(actions.restoreSavedGame(game)) }, [])
    const toggleSound = useCallback(() => {
        dispatch(actions.toggleSound());
        gameService.toggleSound();
    }, []);

    return {
        state, selectTube, resetSelectedTube, restartLevel, stepBack, useEmptyTube, pourLiquid, nextLevel,
        restoreSavedGame, toggleSound
    };
}

type ContextType = ReturnType<typeof usePuzzleContextData>;

const PuzzleStateContext = createContext<ContextType | null>(null);

export function usePuzzleContext() {
    return useContext(PuzzleStateContext)!
}
