import { v4 as uuid } from 'uuid';
import { createDateStamp, Game } from "../data/game.service";
import { Puzzle, checkTubeReward, colorsRange, createPuzzleGenerator } from "../game";
import { appSettings } from '../settings/app-settings';

export interface PuzzleState {
    level: number
    firstAttemptLevelsPass: number
    today: { // today's counters
        levels: number,
        firstAttemptLevelsPass: number
    },
    colorNumber: number
    puzzle: Puzzle
    remainingStepsBack: number
    remainingEmptyTubes: number
    selectedTubeId?: string
    stepHistory: Step[]
    isGeneratingLevel?: boolean
    emptyTubeUsageLevel?: number
    isRetryAttempt: boolean
    mute: boolean
}

interface Step {
    sourceId: string
    targetId: string
    capacity: number
    reversedStepIndex?: number
}

export const puzzleReducerInitialState: PuzzleState = {
    level: 1,
    firstAttemptLevelsPass: 0,
    today: {
        levels: 0,
        firstAttemptLevelsPass: 0
    },
    colorNumber: 1,
    puzzle: createPuzzleGenerator(1).generate(),
    remainingStepsBack: appSettings.remainingStepsBack,
    remainingEmptyTubes: 1,
    stepHistory: [],
    isRetryAttempt: false,
    mute: false
}

interface Action {
    type: 'RESTART_LEVEL' | 'STEP_BACK' | 'USE_EMPTY_TUBE' | 'RESET_SELECTED_TUBE' | 'TOGGLE_SOUND' | 'NEXT_LEVEL'
}

interface SelectTubeAction {
    type: 'SELECT_TUBE'
    payload: { selectedTubeId: string }
}

interface PourLiquidAction {
    type: 'POUR_LIQUID'
    payload: {
        sourceId: string
        targetId: string
        capacity: number // number of liquid items to pour
    }
}

interface RestoreSavedGameAction {
    type: 'RESTORE_SAVED_GAME'
    payload: {
        game: Game
    }
}

export const actions = {
    selectTube: (selectedTubeId: string): SelectTubeAction => ({ type: 'SELECT_TUBE', payload: { selectedTubeId } }),
    resetSelectedTube: (): Action => ({ type: "RESET_SELECTED_TUBE" }),
    restartLevel: (): Action => ({ type: "RESTART_LEVEL" }),
    stepBack: (): Action => ({ type: "STEP_BACK" }),
    useEmptyTube: (): Action => ({ type: "USE_EMPTY_TUBE" }),
    pourLiquid: (sourceId: string, targetId: string, capacity: number): PourLiquidAction => ({ type: 'POUR_LIQUID', payload: { sourceId, targetId, capacity } }),
    nextLevel: (): Action => ({ type: 'NEXT_LEVEL' }),
    restoreSavedGame: (game: Game): RestoreSavedGameAction => ({ type: "RESTORE_SAVED_GAME", payload: { game } }),
    toggleSound: (): Action => ({ type: "TOGGLE_SOUND" })
}

export function puzzleReducer(
    state: PuzzleState = puzzleReducerInitialState,
    action: Action | SelectTubeAction | PourLiquidAction | RestoreSavedGameAction): PuzzleState {
    switch (action.type) {
        case "SELECT_TUBE": return {
            ...state,
            selectedTubeId: action.payload.selectedTubeId
        }
        case 'RESET_SELECTED_TUBE': return {
            ...state,
            selectedTubeId: undefined
        }
        case "STEP_BACK": {
            const lastStep = excludeReverseSteps(state.stepHistory).at(-1);
            if (!lastStep) return state;

            return {
                ...state,
                selectedTubeId: undefined,
                remainingStepsBack: state.remainingStepsBack - 1,
                stepHistory: state.stepHistory.concat({
                    sourceId: lastStep.targetId,
                    targetId: lastStep.sourceId,
                    capacity: lastStep.capacity,
                    reversedStepIndex: state.stepHistory.indexOf(lastStep)
                }),
                puzzle: pourLiquidReducer(
                    state.puzzle,
                    lastStep.targetId,
                    lastStep.sourceId,
                    lastStep.capacity
                )
            }
        }
        case "USE_EMPTY_TUBE": return {
            ...state,
            remainingEmptyTubes: state.remainingEmptyTubes - 1,
            emptyTubeUsageLevel: state.level,
            puzzle: {
                ...state.puzzle,
                tubes: state.puzzle.tubes.concat({ id: uuid(), liquid: [] }),
            }
        }
        case 'POUR_LIQUID': return {
            ...state,
            puzzle: pourLiquidReducer(
                state.puzzle,
                action.payload.sourceId,
                action.payload.targetId,
                action.payload.capacity),
            selectedTubeId: undefined,
            stepHistory: state.stepHistory.concat({
                sourceId: action.payload.sourceId,
                targetId: action.payload.targetId,
                capacity: action.payload.capacity
            }) // track step
        }
        case 'NEXT_LEVEL': {
            const colorNumber = Math.min(state.colorNumber + 1, colorsRange.max);
            const tubeReward = checkTubeReward(state.level, state.remainingEmptyTubes, state.emptyTubeUsageLevel)

            return {
                ...puzzleReducerInitialState,
                level: state.level + 1,
                firstAttemptLevelsPass: state.isRetryAttempt
                    ? state.firstAttemptLevelsPass
                    : state.firstAttemptLevelsPass + 1,
                today: {
                    levels: state.today.levels + 1,
                    firstAttemptLevelsPass: state.isRetryAttempt ? state.today.firstAttemptLevelsPass : state.today.firstAttemptLevelsPass + 1
                },
                colorNumber,
                puzzle: createPuzzleGenerator(colorNumber).generate(),
                remainingEmptyTubes: tubeReward ? state.remainingEmptyTubes + 1 : state.remainingEmptyTubes,
                emptyTubeUsageLevel: tubeReward ? undefined : state.emptyTubeUsageLevel,
                mute: state.mute
            };
        }
        case 'RESTART_LEVEL': {
            let puzzle = state.puzzle;
            const steps = excludeReverseSteps(state.stepHistory);

            while (steps.length) {
                const step = steps.pop();
                if (step) {
                    puzzle = pourLiquidReducer(puzzle, step.targetId, step.sourceId, step?.capacity);
                }
            }

            return {
                ...state,
                puzzle,
                stepHistory: [],
                selectedTubeId: undefined,
                isRetryAttempt: true,
                remainingStepsBack: appSettings.remainingStepsBack
            }
        }
        case 'RESTORE_SAVED_GAME': {
            const colorNumber = Math.min(action.payload.game.level, colorsRange.max);

            const shouldAddTubeHalper = action.payload.game.level === action.payload.game.emptyTubeUsageLevel;
            const today = action.payload.game.days[createDateStamp()] ?? {
                levels: 0,
                firstAttemptLevelsPass: 9
            };


            return {
                ...puzzleReducerInitialState,
                level: action.payload.game.level,
                colorNumber,
                puzzle: createPuzzleGenerator(colorNumber).generate(shouldAddTubeHalper ? 1 : 0),
                remainingEmptyTubes: action.payload.game.tubeHelper,
                firstAttemptLevelsPass: action.payload.game.firstAttemptLevelsPass,
                today,
                emptyTubeUsageLevel: action.payload.game.emptyTubeUsageLevel,
                mute: action.payload.game.mute
            }
        }
        case "TOGGLE_SOUND": return {
            ...state,
            mute: !state.mute
        }

        default: return state;
    }
}

function pourLiquidReducer(state: Puzzle, sourceId: string, targetId: string, capacity: number) {
    const source = state.tubes.find(b => b.id === sourceId);
    const liquidToPour = source?.liquid.slice(0, capacity);

    if (!liquidToPour) return state;

    return {
        ...state,
        tubes: state.tubes.map(b =>
            (b.id === sourceId && { ...b, liquid: b.liquid.slice(capacity) }) || // remove top liquid item from the source tube 
            (b.id === targetId && { ...b, liquid: liquidToPour.concat(b.liquid) }) || // add liquid item to the target
            b) // left other elements untouched
    }
}

export function excludeReverseSteps(history: Step[]) {
    const reverseStepIndexes = history
        .filter(step => step.reversedStepIndex != null)
        .map(step => step.reversedStepIndex!);

    return history.filter((step, i) => step.reversedStepIndex == null && !reverseStepIndexes.some(ri => ri === i));
}

