import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    text: string
    onClick: () => void
}

export function DropDownMenuItem({ text, children, onClick }: Props) {
    return(
        <div className="drop-down-menu-item" onClick={onClick}>
            <div className="text">{text}</div>
            <div className="content">{children}</div>
        </div>
    );
}