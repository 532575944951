import { v4 as uuid } from 'uuid';
import { appSettings } from '../settings/app-settings';

export enum Color {
    LightBlue = "LightBlue",
    Orange = "Orange",
    Green = "Green",
    Red = "Red",
    DarkBlue = "DarkBlue",
    Pink = "Pink",
    Yellow = "Yellow",
    Gray = "Gray",
    Brown = "Brown"
}

export const colorsRange = {
    min: 1,
    max: Object.values(Color).length
};

export interface Tube {
    id: string
    liquid: Color[]
}

export interface Puzzle {
    tubes: Tube[]
    tubeSize: number
}

export function getDailyChallenge() {

}

export function checkTubeReward(level: number, remainingEmptyTubes: number, emptyTubeUsageLevel: number = 0) {
    return !remainingEmptyTubes && level - emptyTubeUsageLevel >= appSettings.tubeRewardLevelsToPass
}

export function isTubeDone(tube: Tube, tubeSize: number) {
    return (
        tube.liquid.length === tubeSize &&
        new Set(tube.liquid).size === 1);
}

export function isPuzzleDone(puzzle: Puzzle) {
    const isEmptyOrDone = (tube: Tube) => {
        if (tube.liquid.length === 0) return true; // empty
        if (isTubeDone(tube, puzzle.tubeSize)) return true; // done

        return false;
    }

    return !puzzle.tubes.some(b => !isEmptyOrDone(b))
}

export function createPuzzleGenerator(colorNumber: number): PuzzleGenerator {
    switch (colorNumber) {
        case 1: return new EntryLevelPuzzleGenerator();
        case 2: return new RandomPuzzleGenerator(colorNumber, 3, 1);
        default: return new RandomPuzzleGenerator(colorNumber);
    }
}

interface PuzzleGenerator {
    generate(extraEmptyTubes?: number): Puzzle;
}

class EntryLevelPuzzleGenerator implements PuzzleGenerator {
    generate() {
        const [color] = Object.values(Color);

        return {
            tubes: [
                { id: uuid(), liquid: [color] },
                { id: uuid(), liquid: [color] }
            ],
            tubeSize: 2
        };
    }
}

class RandomPuzzleGenerator implements PuzzleGenerator {
    private _colorNumber: number
    private _tubeSize: number
    private _emptyTubes: number

    constructor(colorNumber: number, tubeSize = 4, emptyTubes = 2) {
        this._colorNumber = colorNumber;
        this._tubeSize = tubeSize;
        this._emptyTubes = emptyTubes;
    }

    public generate(extraEmptyTubes?: number): Puzzle {
        const colors = Object.values(Color).slice(0, this._colorNumber); // get required number of colors
        const tubes: Tube[] = Array(this._colorNumber).fill(0).map(() => ({ id: uuid(), liquid: [] })); // generate empty tubes

        const colorUsage: { [key in Color]?: number } = {};

        for (let i = 0; i < tubes.length; i++) {
            const tube = tubes[i];
            let previousColorIndex: number | undefined; // avoid color repeat
            for (let j = 0; j < this._tubeSize; j++) {
                // get a random value in the range 0-colors.length
                const colorIndex = colors.length === 1 ? 0 : random(colors.length, previousColorIndex)
                const color = colors[colorIndex];
                tube.liquid.push(color);

                let usage = colorUsage[color] ?? 0;
                colorUsage[color] = usage + 1;
                if (colorUsage[color] === this._tubeSize) {
                    // color is not available anymore, exclude it from future selection
                    colors.splice(colorIndex, 1);
                    previousColorIndex = undefined
                } else {
                    previousColorIndex = colorIndex;
                }

            }
        }

        if (tubes.some(t => isTubeDone(t, this._tubeSize))) {
            // regenerate one more time
            console.log('Completed tube coincidence, try rearrange');
            return this.generate(extraEmptyTubes);
        }

        return {
            tubes: [
                ...tubes,
                ...Array(this._emptyTubes + (extraEmptyTubes ?? 0)).fill(0).map(() => ({ id: uuid(), liquid: [] })) // Empty tubes
            ],
            tubeSize: this._tubeSize
        };

    }
}

function random(max: number, exclude?: number): number {
    const value = Math.floor(Math.random() * max);
    return value === exclude ? random(max, exclude) : value;
}